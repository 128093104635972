/**
 * 用户登录页
 */
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { FormProps } from 'antd/es/form';
import { apiCampaignList } from './services';
import logo from '@/assets/img/logo-1.png';
import './index.less';

interface FormProp {
  username?: string;
  password?: string;
}
const FormItem = Form.Item;
const LoginComponent = () => {
  const [form] = Form.useForm();
  const params = useParams<{ redirectTo?: string }>();
  const login: FormProps['onFinish'] = useCallback(() => {
    form.validateFields().then(res => {
      const values = res as FormProp;
      if (values.username && values.password) {
        apiCampaignList({
          username: values.username,
          password: values.password,
        }).then(res => {
          const data = res.data.data;
          const { access_token, api_token, refresh_token, user_number } = data;
          localStorage['api_token'] = api_token;
          localStorage['access_token'] = access_token;
          localStorage['refresh_token'] = refresh_token;
          localStorage['user_number'] = user_number;
          const { redirectTo } = params;
          const redirect = redirectTo ? JSON.parse(redirectTo) : '/serving2';
          window.history.replaceState('', '', redirect);
          window.dispatchEvent(new PopStateEvent('popstate', { state: null }))
        }).catch(error =>{
          if(error.response&&error.response.data&&error.response.data.code){
            if((error.response.data.code<200||error.response.data.code>300)&&error.response.data.msg)
            message.error(error.response.data.msg);
          }
        })
      }
    })
  },
    [form, params],
  );


  return (
    <div className="wrapper">
      <div className="main">
      <img src={logo} alt='' className="logo" />
      <Form form={form} onFinish={login}>
      <FormItem
        name='username'
        rules={[
          {
            required: true,
            message: '请填写用户名',
          },
        ]}
      >
        <Input size="large" prefix={<UserOutlined />} placeholder='用户名' onPressEnter={() => form.submit()} />
      </FormItem>
      <FormItem
        name='password'
        rules={[
          {
            required: true,
            message: '请填写密码',
          },
        ]}
      >
        <Input.Password
          size="large"
          prefix={<LockOutlined />}
          placeholder='密码'
          onPressEnter={() => form.submit()}
        />
      </FormItem>
      <Form.Item className='text-right'>
        <a target='_blank' href='http://account.mininglamp.com/retrievePassword' rel="noreferrer">
          忘记/重置密码
        </a>
      </Form.Item>
      <FormItem>
        <Button size='large' type='primary' htmlType='submit' className='w-full'>
          登录
        </Button>
      </FormItem>
    </Form>
      </div>
    </div>
  );
};

export default LoginComponent;
